.action {
  border-radius: 7.5px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  height: 30px;
  width: 30px;
  background-color: inherit;
  cursor: pointer;
  transition: all 400ms ease;
}

.action:hover {
  border: 1px solid var(--contrast-color);
  transition: all 400ms ease;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
}

.add {
  width: 100%;
  height: 50px;
  align-self: center;
  background-color: inherit;
  background-color: var(--main-color);
  border: none;
  color: var(--contrast-color);
  border-radius: 0 0 7.5px 7.5px;
  cursor: pointer;
  transition: all 400ms ease;
}