.content_container {
  width: calc(90vw / 4 - 45px);
}

.dish {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 300px;
  justify-content: space-between;
  max-width: 200px;
  /* padding: 10px; */
  border-radius: 7.5px;
  transition: all 200ms ease-in-out;
  background-color: white;
}

.dish:hover, .dish:active {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  transition: all 200ms ease-in-out;
}

.img {
  width: 100%;
  height: 150px;
  border-radius: 7.5px 7.5px 0 0;
  outline: none;
  border: none;
  appearance: none;
  cursor: pointer;
}

.gag {
  background-image: url("../../../public/no-imge.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  border-radius: 5%;
}

.name {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-transform: uppercase;
  height: 57px;
}

.price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.actions {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 60%;
  height: 50px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.action {
  border-radius: 7.5px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  height: 30px;
  width: 30px;
  background-color: inherit;
  cursor: pointer;
  transition: all 400ms ease;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: 86px;
  padding: 0 10px;
}
