@keyframes popup {
  0% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  33% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  67% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.menu {
  position: absolute;
  top: 99px;
  height: calc(100vh - 99px);
  width: 100vw;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dishesContainer {
  max-width: 900px;
  justify-content: space-around;
  display: grid;
  grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) minmax(150px, 200px) minmax(150px, 200px) ;
  column-gap: 15px;
  row-gap: 25px;
  padding: 10px 5px 80px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 780px) {
  .dishesContainer {
    grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) minmax(150px, 200px);
  }
}

@media screen and (max-width: 560px) {
  .dishesContainer {
    grid-template-columns: minmax(150px, 200px) minmax(150px, 200px);
  }
}


