@media screen and (min-width: 900px) {
  .header {
      left: 5vw;
      right: 5vw;
      width: 90vw;
  }
}

.header_wrapper {
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  /* max-width: 900px; */
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: 0px 0px 10px 0px rgb(222 222 222 / 50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header_wrapper::-webkit-scrollbar {
  display: none;
}

.header {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  gap: 7px;
  width: calc(100% - 10px);
  max-width: 900px;
  flex-wrap: nowrap;
  padding: 5px;
}
.header::-webkit-scrollbar {
  display: none;
}

.link {
  color: var(--contrast-color);
  appearance: none;
  text-decoration: none;
  height: 39px;
  font-size: 15px;
  line-height: 39px;
  z-index: 1;
  border-radius: 20px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 1px #ccc;
  align-self: stretch;
  padding: 0px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


