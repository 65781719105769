.link {
  color: rgba(27, 26, 61, 0.8);
  appearance: none;
  text-decoration: none;
  height: 39px;
  font-size: 15px;
  line-height: 39px;
  z-index: 10;
  border-radius: 5%;
  border: none;
  align-self: stretch;
  padding: 0px 15px;
  white-space: nowrap;
  transition: all 1s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}

.link:hover {
  cursor: pointer;
  background-color: rgba(27, 26, 61, 0.1);
  transition: all 0.5s ease;
}

.link:focus,
.link:active {
  outline: none;
}

.linkActive {
  color: rgba(27, 26, 61);
  font-weight: 500;
  background-color: var(--out-color);
}

.linkActive:hover {
  background-color: rgba(6, 143, 186, 0.6);
  color: white;
  transition: all 0.5s ease;
  cursor: pointer;
}
