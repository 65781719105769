
.cart_header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.back {
  color: var(--contrast-color);
  background-color: white;
  font-size: 18px;
  line-height: 24px;
  appearance: none;
  border: none;
  outline: none;
  z-index: 11;
  cursor: pointer;
  padding: 10px;
  margin: 0;
  transition: all 200ms ease-in-out;
  background-color: transparent;
}

.back:active {
  color: #0353A4;
  background-color: transparent;
  text-decoration: underline;
  border-radius: 7.5px;
  transition: all 200ms ease-in-out;
}

.title {  
  z-index: 10;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  margin: 0;
  padding: 0 10px;
}