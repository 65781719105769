
.cart {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartContainer {
    width: 90vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 60px auto;
    padding: 30px 20px;
    box-sizing: border-box;
    /* height: calc(95vh - 50px); */
    border: 1px solid rgba(220, 220, 220);
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(220, 220, 220);
    background-color: white;
}

.cartContainer::-webkit-scrollbar {
  width: 0;
}

.dishesWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.clean:disabled {
  cursor: default;
}

.account {
  color: var(--contrast-color);
  font-size: 16px;
  bottom: 100px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  background-color: transparent;
  z-index: 10;
  margin-top: 24px;
}

.delivery {
  width: fit-content;
}

.result {
  width: fit-content;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.submit {
  border: 1px solid rgba(220, 220, 220);
/* min-height: 18px; */
padding: 15px 20px;
font-size: 18px;
line-height: 18px;
-webkit-appearance: none;
-ms-appearance: none;
-moz-appearance: none;
appearance: none;
width: 100%;
appearance: none;
border-radius: 7.5px;
background-color: #FFE07A;
cursor: pointer;
}

