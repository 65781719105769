.content_container {
  width: 90vw;
}

.dish {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  max-width: 450px;
  width: 85vw;
  border-radius: 7.5px;
  transition: all 200ms ease-in-out;
  background-color: white;
}

.dish:hover, .dish:active {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  transition: all 200ms ease-in-out;
}

.img {
  width: 100%;
  height: auto;
  border-radius: 7.5px 7.5px 0 0;
  outline: none;
  border: none;
  appearance: none;
}

.name {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
}

.price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.actions {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 60%;
  height: 50px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
  margin-bottom: 5px;
}
