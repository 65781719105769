
.wrapper {
  position: sticky;
  bottom: 0;
  z-index: 99;
  margin: 0 auto;
  width: 70vw;
  max-width: 900px;
  right: 15vw;
  left: 15vw;
  background-color: transparent;
}

.button {
  background-color: #FFE07A;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 40%);
  border-radius: 10px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 25px;
  display: none;
}

.button_active {
  display: inline-block;
}

.button_:active:hover{
  opacity: 0.95;
  transition: all 200ms ease-in-out;
}

.button:disabled {
  background-color: gray;
  cursor: default;
  box-shadow: none;
}