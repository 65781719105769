@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../public/fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../public/fonts/Inter-Regular.ttf');
}

@media (prefers-reduced-motion: no-preference) {
	.scroll-container {
  scroll-behavior: smooth;
  }
}

:root {
  --main-color: #FFDA62;
  --out-color: #FFE07A;
  --contrast-color: #1F1909;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--contrast-color);
  background-color: rgba(153, 152, 179, 0.1)
}

input {
  color: var(--contrast-color);
}

button {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body::-webkit-scrollbar { width: 0; }

body { -ms-overflow-style: none; }

body { overflow: -moz-scrollbars-none; }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}