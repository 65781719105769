.dish {
  display: flex;
  height: 120px;
  justify-content: space-between;
  width: calc(100% - 10px);
  margin: 0 auto;
  gap: 15px;
  padding: 5px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.info {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  align-self: center;
}

.actions {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100px;
  justify-content: space-between;
}

.img {
  width: 120px;
  height: 90px;
  border-radius: 5%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.gag {
  background-image: url("../../../public/no-imge.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 120px;
  height: 90px;
  border-radius: 5%;
}

.contentContainer {
  display: flex;
  gap: 20px
}

@media screen and (max-width: 550px) {
  .dish {
    height: 140px;
    flex-direction: column;
    gap: 15px
  }

  .actions {
    align-self: self-end;
  }
}