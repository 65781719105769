.cartContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
  transform: rotate(0);
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.form {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 60px auto;
  padding: 30px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(220, 220, 220);
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(220, 220, 220);
  background-color: white;
}

.button {
  position: absolute;
  top: calc(100vh - 50px);
  left: 5vw;
  right: 5vw;
  z-index: 98;
  background-color: #28a745;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 50%);
  border-radius: 10px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  color: white;
  border: pointer;
}

.inputContainer {
  display: flex;
}

.inputContainer input {
  width: 50%;
}

.toggle {
  height: 50px;
  width: 50%;
  border: none;
  background-color: lightgrey;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 18px;
  line-height: 50px;
  color: rgba(153, 152, 179);
  border-radius: 7.5px;
  cursor: pointer;
}
.toggleActive {
  background-color: #FFE07A;
  border-radius: 7.5px;
  color: var(--contrast-color);
}

.togglesContainer {
  background-color: lightgrey;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 7.5px;
  border: none;
  position: relative;
  z-index: 5;
}

.back {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  height: 50px;
  width: 50px;
  appearance: none;
  border: none;
  outline: none;
  z-index: 11;
  background-color: rgba(220, 220, 220);
  cursor: pointer;
}

.account {
  color: var(--contrast-color);
  font-size: 16px;
  bottom: 100px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  background-color: transparent;
  z-index: 10;
  margin-top: 24px;
}

.delivery {
  width: fit-content;
}

.result {
  width: fit-content;
  font-weight: 500;
  font-size: 24px;
}

.submit {
  border: 1px solid rgba(220, 220, 220);
  padding: 15px 20px;
  font-size: 18px;
  line-height: 18px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  appearance: none;
  border-radius: 7.5px;
  background-color: #FFE07A;
  cursor: pointer;
}

.title {
  margin: 0;
  display: block;
}

@media screen and (max-height: 550px) {
  .form {
    height: fit-content;
  }
}