.root {
  background-color: var(--main-color);
  border-radius: 7.5px;
  padding: 10px 15px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  text-decoration: none;
  text-align: center;
  width: calc(100% - 30px);
  box-shadow: 0 0 10px 0 rgba(0,0,0, 20%);
}