.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:10px 5px ;
  height: calc(100vh - 20px);
  width: calc(100vw - 10px);
}

.navContainer {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr); */
  display: flex;
  flex-direction: column;
  gap: 15px;
  row-gap: 15px;
  column-gap: 7.5px;
  width: 100%;
  justify-content: center;
}